import React from 'react'
import "./IncidentSourcePreview.scss"
import 'react-resizable/css/styles.css';
import {Fab, Paper, Stack, Tooltip} from "@mui/material";
import {IIncidentReport} from "../../services/types/incident";
import CancelIcon from "@mui/icons-material/Cancel";
import {getTextDirection} from "../../services/multiLangUI";
import {Rnd} from "react-rnd";
import Highlighter from 'react-highlight-words';
import {t} from "@lingui/core/macro";


export interface ISourcePreview {
    source: IIncidentReport,
    defaultPos: { x: number, y: number },
}

interface ISourcePreviewProps extends ISourcePreview {
    onClose: () => void,
}

interface IState {
    displayMode: "reader" | "original"
}


export default class SourcePreview extends React.Component<ISourcePreviewProps, IState> {
    constructor(props: ISourcePreviewProps) {
        super(props);
        this.state = {
            displayMode: "reader"
        }
    }

    async componentDidMount() {
    }

    render() {
        const source = this.props.source;
        const displayMode = this.state.displayMode;
        const previewTitle = source.post_title || (source.website_title + ", " + source.post_publication_date.split("T")[0]);
        return <Rnd
            dragHandleClassName={"draggable-handle-" + source.id}
            default={{
                x: this.props.defaultPos.x,
                y: this.props.defaultPos.y,
                width: 300,
                height: 350,
            }}
            style={{
                pointerEvents: "all"
            }}
            enableResizing={true}
        >
            <Paper
                className={"source-preview-wrap"}
                sx={{
                    width: "100%",
                    display: "block"
                }}
            >
                <Stack gap={1} sx={{height: "100%"}}>
                    <div
                        className={
                            "source-preview-header-wrap " +
                            "draggable-handle-" + source.id}
                        dir={getTextDirection(source.post_title)}
                    >
                        <Stack direction={"row"} className={"source-preview-header"} gap={1}>
                            <div style={{
                                direction: getTextDirection(previewTitle),
                                textAlign: getTextDirection(previewTitle) === "rtl" ? "right" : "left",
                            }}>
                                {previewTitle}
                            </div>
                            <Stack>
                                <Tooltip title={t`Close`} arrow placement={"bottom"}>
                                    <Fab
                                        aria-label={t`Close`}
                                        color={"error"}
                                        size={"small"}
                                        onClick={() => {
                                            this.props.onClose();
                                        }}
                                        onTouchEnd={() => {
                                            this.props.onClose();
                                        }}
                                        sx={{minWidth: "auto", minHeight: "auto"}}
                                    >
                                        <CancelIcon fontSize={"small"}/>
                                    </Fab>
                                </Tooltip>
                            </Stack>
                        </Stack>
                    </div>
                    <div className={"source-preview-body"}>{
                        displayMode === "original" && source.post_html ?
                            <iframe
                                title={source.post_title}
                                srcDoc={source.post_html}
                                style={{width: "100%"}}
                            /> :
                            <div
                            style={{
                                direction: getTextDirection(source.post_body),
                                textAlign: getTextDirection(source.post_body) === "rtl" ? "right" : "left",
                            }}
                            >
                                {
                                    (source.post_body || "").split(/\n+/)
                                        .filter(paragraph => paragraph.trim() !== '')
                                        .map((paragraph, index) => (
                                            <p key={index}>
                                                <Highlighter
                                                    textToHighlight={paragraph}
                                                    searchWords={source.quote ? [source.quote] : []}
                                                />
                                            </p>
                                        ))
                                }
                            </div>
                    }</div>
                </Stack>
            </Paper>
        </Rnd>
    }
}