import {DataItem, DataQuery, IDataField} from "./entityFields";
import {Entity} from "./entityBase";
import React from "react";


export interface IUser extends DataItem {
    create_date?: Date;
    email: string;
    locked: boolean;
    admin: boolean;
}

export interface IUserQuery extends DataQuery<IUser> {
}

export class User extends Entity<IUser, IUserQuery> {
    title = "Users";
    endpoint = "users";
    slug = "users";
    fields: IDataField<IUser>[] = [
        {title: "ID", key: "id", field_type: "number", disabled: true},
        {title: "Email", key: "email", field_type: "str"},
        {title: "Creation Date", key: "create_date", field_type: "str", disabled: true},
        {title: "Locked", key: "locked", field_type: "bool"},
        {title: "Admin", key: "admin", field_type: "bool"},
    ];
    defaultNewItem: IUser = {
         email: "", admin: false, locked: false
    };
    accordionHeader = (r: IUser) => <div
        style={{
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxHeight: "3em",
            lineHeight: "1em",
            width: "100%"
        }}
    >{
        r.id ? r.email : "New User"
    }</div>
}