import React from 'react';
import server from '../services/server';
import cookie from 'js-cookie';
import './login/Login.scss';
import withRouter, {IRouterProps} from "../services/withRouter"
import {
    Button,
    CircularProgress,
    FormControl,
    Grow,
    IconButton,
    Input,
    InputLabel,
    Modal,
    Stack, Tooltip,
} from "@mui/material";
import {encodeBrowseWorkspaceSearchParams, getDefaultBrowseWorkspace} from "../services/types/workspace";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import AlignDir from "../services/languages/AlignDir";
import {LocalFlorist, Mail} from "@mui/icons-material";
import withNotifications, {WithNotificationProps} from "../UIComponents/IncidentNotifications/withNotifications";
import {NOTIFICATION_TYPE} from "../UIComponents/IncidentNotifications/NotificationsContext";
import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';


type IProps = {} & IRouterProps & WithNotificationProps

interface IState {
    redirect?: string,
    email: string,
    awaitingCodeSend: boolean,
    codeSent: boolean,
    code: string,
    awaitingAuthentication: boolean,
    authenticationError: string | null
}

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

class Login extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            redirect: "",
            email: props.searchParams.get("email") || "",
            awaitingCodeSend: false,
            codeSent: false,
            code: props.searchParams.get("code") || "",
            awaitingAuthentication: false,
            authenticationError: null
        };
    }

    async componentDidMount() {
        const token = cookie.get('token')
        if (token && token.length) {
            server.get("permissions/", {ignoreErrors: true}).then((res) => {
                if (res.valid) {
                    const unapprovedFilter: { [key: string]: any } = {
                        "s": encodeBrowseWorkspaceSearchParams(
                            getDefaultBrowseWorkspace(["not approved"])
                        )
                    };
                    this.props.navigate({
                        pathname: "/incidents/",
                        search: Object.keys(unapprovedFilter).map(k => "" + k + "=" + unapprovedFilter[k]).join("&")
                    });
                }
            })
        }
        if (this.state.code.length && this.state.email.length) {
            await this.verifyLoginCode()
        }
    }

    validateEmail = (email: string) => {
        //checks for example@example.example, such that [example] doesn't contain a '@'
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };


    identifyByEmail = () => {
        if (this.state.awaitingCodeSend) {
            return
        }
        this.setState((curr) => ({...curr, awaitingCodeSend: true}), async () => {
            await server.post('login/identify/', {'email': this.state.email});
            this.setState((curr) => ({...curr, codeSent: true, awaitingCodeSend: false}), () => {
                this.props.notificationContext?.addNotification({
                    notificationType: NOTIFICATION_TYPE.EMAIL,
                    actionType: null,
                    title: t`Your Login Code Has Been Sent To Your Email`
                })
            });
        });
    }

    async verifyLoginCode() {
        if (this.state.awaitingAuthentication) {
            return
        }
        const data = {'email': this.state.email, 'code': this.state.code};
        this.setState((curr) => ({...curr, awaitingAuthentication: true}), async () => {
            const res = await server.post("login/login/", data);
            if (res["token"]) {
                this.setState((curr) => ({...curr, awaitingAuthentication: false}));
                cookie.set('token', res["token"], {expires: 30});
                if (this.state.redirect) {
                    try {
                        this.props.navigate(decodeURIComponent(this.state.redirect));
                        return;
                    } catch (e) {
                        console.log(e)
                    }
                }
                const unapprovedFilter: { [key: string]: any } = {
                    "s": encodeBrowseWorkspaceSearchParams(
                        getDefaultBrowseWorkspace(["not approved"])
                    )
                };
                this.props.navigate({
                    pathname: "/incidents/",
                    search: Object.keys(unapprovedFilter).map(k => "" + k + "=" + unapprovedFilter[k]).join("&")
                });
            } else {
                const error = res?.["error"] || t`An Unknown Error Has Occurred`;
                this.setState((curr) => ({...curr, awaitingAuthentication: false, authenticationError: error}));
            }
        });
    }


    render() {
        /**Stage 1 - Verification Method Selection**/
        const identification =
            <Stack dir={"column"} alignItems={"center"} gap={2} sx={{width: "100%"}}>
                <h2>Login</h2>
                <FormControl
                    variant="outlined"
                    sx={{
                        width: (window.innerWidth <= 768) ? "80%" : "500px"
                    }}
                >
                    <InputLabel>
                        Email Address
                    </InputLabel>
                    <Input
                        value={this.state.email}
                        dir="ltr"
                        onChange={(e) => {
                            this.setState((curr) => ({...curr, email: e.target.value}))
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                this.identifyByEmail();
                            }
                        }}
                        endAdornment={
                            <Grow
                                in={this.validateEmail(this.state.email)}
                                style={{transformOrigin: '0 0 0'}}
                            >
                                {
                                    this.state.awaitingCodeSend ?
                                        <CircularProgress size={"small"}/> :
                                        <Tooltip
                                            title={t`Send me the login code`}
                                            placement={"top"}
                                            arrow
                                            disableInteractive
                                        >
                                            <IconButton color={"primary"} size={"small"} onClick={this.identifyByEmail}>
                                                <Mail/>
                                            </IconButton>
                                        </Tooltip>
                                }
                            </Grow>
                        }
                    />
                </FormControl>
            </Stack>;

        /**Stage 2 - Login Code Input**/
        const authentication = <Stack dir={"column"} alignItems={"center"} gap={2} sx={{width: "100%"}}>
            <h2>Input the code you were sent in your mail</h2>
            <FormControl
                variant="outlined"
                sx={{
                    width: (window.innerWidth <= 768) ? "80%" : "500px"
                }}
            >
                <InputLabel>
                    Code
                </InputLabel>
                <Input
                    value={this.state.code} 
                    dir="ltr"
                    onChange={(e) => {
                        this.setState((curr) => ({...curr, code: e.target.value}))
                    }}
                    onKeyDown={async (e) => {
                        if (e.key === 'Enter') {
                            await this.verifyLoginCode();
                        }
                    }}
                    endAdornment={
                        <Grow

                            in={this.state.code.length > 5}
                            style={{transformOrigin: '0 0 0'}}
                        >
                            {
                                this.state.awaitingAuthentication ?
                                    <CircularProgress size={"small"}/> :
                                    <Tooltip
                                        title={"Login"}
                                        placement={"top"}
                                        arrow
                                        disableInteractive
                                    >
                                        <IconButton color={"success"} size={"small"} onClick={this.verifyLoginCode}>
                                            <LocalFlorist/>
                                        </IconButton>
                                    </Tooltip>
                            }
                        </Grow>
                    }
                />
            </FormControl>
            <Button
                variant={"text"}
                color={"primary"}
                onClick={() => {
                    this.setState((curr) => ({...curr, codeSent: false}));
                }}
            >
                Re-enter Email
            </Button>
        </Stack>;

        return (
            <AlignDir direction={"ltr"}>
                <div className='page-wrap-login'>
                    <ThemeProvider theme={darkTheme}>
                        <Stack dir={"column"} alignItems={"center"} gap={2}>
                            {
                                (window.innerWidth <= 768) ?
                                    <Stack className={"welcome-title-wrap"} direction={"column"}
                                           justifyContent={"center"}>
                                        <h1 className={"welcome-title"}>Welcome to the Magrefa</h1>
                                        <Stack className={"welcome-title-wrap"} direction={"row"}
                                               justifyContent={"center"}>
                                            <h1 className={"title-adornments"}>°𓏲🌿. 🍁⋆˚࿔</h1>
                                            <h1 className={"title-adornments"}>༄˖°.🍂.ೃ࿔*</h1>
                                        </Stack>
                                    </Stack> :
                                    <Stack className={"welcome-title-wrap"} justifyContent={"center"} direction={"row"}
                                           gap={2}>
                                        <h1 className={"title-adornments"}>°𓏲⋆🌿🍁⋆˚࿔</h1>
                                        <h1 className={"welcome-title"}>Welcome to the Magrefa</h1>
                                        <h1 className={"title-adornments"}>༄˖°.🍂.ೃ࿔*</h1>
                                    </Stack>
                            }
                            {
                                this.state.codeSent ? authentication : identification
                            }
                        </Stack>
                    </ThemeProvider>
                </div>
                <Modal
                    open={!!this.state.authenticationError}
                    onClose={() => {
                        this.setState((curr) => ({...curr, authenticationError: null}))
                    }}
                    className={"modal-center"}
                >
                    <div className={"authentication-modal"}>
                        <h2><Trans>Authentication Error</Trans></h2>
                        <p>{this.state.authenticationError}</p>
                    </div>
                </Modal>
            </AlignDir>
        )
    }
}

export default withRouter(withNotifications<IProps>(Login));

