import React, {Component} from 'react';
import {LocalizationProvider, DatePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    Box,
    CircularProgress, Divider,
    Stack, Tooltip,
    Typography
} from '@mui/material';
import dayjs, {Dayjs} from 'dayjs';
import server from "../../services/server";
import Paper from "@mui/material/Paper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {ISource} from "../../services/entities/sources";
import IncidentInfoCard from "../incident/IncidentInfoCard";

interface CurationSummary {
    merges: Merge[];
    splits: Split[];
    status_changes: StatusChange[];
}

interface Merge {
    user_email: string;
    incident_ids: number[];
    merged_into: number;
}

interface Split {
    user_email: string;
    incident_report_ids: number[];
    from_incident_id: number;
    to_incident_id: number;
}

interface StatusChange {
    user_email: string;
    incident_id: number;
    old_status: string;
    new_status: string;
}

interface IncidentsSummary {
    new_incidents_preview: Incident[];
    incidents_with_new_reports_preview: Incident[];
    new_incidents_count: number;
    incidents_with_new_reports_count: number;
}

interface Incident {
    id: number;
    summary: string;
}

interface ScrapingError {
    id: number;
    timestamp: string;
    job_id: number;
    stage: "extraction" | "screening" | "screening_ai" | "download" | "parse" | "analysis" | "postprocessing" | "clustering";
    source?: ISource;
    source_url?: string;
    source_title?: string;
    post_id?: number;
    post_url?: string;
    post_title?: string;
    incident_report_id?: number;
    details?: string;
    error?: string;
}

interface SourceSummary {
    source?: ISource;
    extracted: number;
    screened_keywords: number;
    screened_ai: number;
    downloaded: number;
    parsed: number;
    analyzed: number;
    yielded_incidents: number;
    errors: ScrapingError[];
}

interface ScrapingSummary {
    job_ids: number[];
    source_summary: SourceSummary[];
}

interface SystemSnapshotSummary {
    approved: number;
    not_approved: number;
    removed: number;
    total: number;
}

interface SystemActivitySummary {
    cover_from_date: string;
    cover_to_date: string;
    system_snapshot_summary: SystemSnapshotSummary;
    scraping_summary: ScrapingSummary;
    incidents_summary: IncidentsSummary;
    curation_summary: CurationSummary;
}

interface IState {
    digest: SystemActivitySummary | null;
    digestLoading: boolean;
    coverFrom: Dayjs;
    coverTo: Dayjs;
}

class SystemDigest extends Component<{}, IState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            digest: null,
            digestLoading: false,
            coverFrom: dayjs().subtract(7, 'day'),
            coverTo: dayjs(),
        };
    }

    async componentDidMount() {
        await this.fetchDigest();
    }

    fetchDigest = async () => {
        this.setState({digestLoading: true}, async () => {
            const {coverFrom, coverTo} = this.state;
            const response = await server.post('insights/digest/', {
                start_date: coverFrom?.toISOString(),
                end_date: coverTo?.toISOString()
            });
            if (response) {
                this.setState({digest: response as SystemActivitySummary, digestLoading: false});
            }
        });
    };

    handleDateChange = (field: 'coverFrom' | 'coverTo') => (date: Dayjs | null) => {
        if (!date) return;
        this.setState((curr) => ({...curr, [field]: date}), async () => {
            await this.fetchDigest()
        });
    };

    renderDigest = (digest: SystemActivitySummary) => {
        const createAccordionSection = (title: React.ReactElement, content: React.ReactElement) => (
            <Accordion disableGutters>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    {title}
                </AccordionSummary>
                <AccordionDetails>
                    {content}
                </AccordionDetails>
            </Accordion>
        );

        const createListSection = (items: string[]) => (
            <Box component="ul" sx={{paddingLeft: 2}}>
                {items.map((item, index) => (
                    <Box component="li" key={index} sx={{marginBottom: 1}}>
                        {item}
                    </Box>
                ))}
            </Box>
        );

        const getIncidentLink = (incidentId: number) => {
            return <Tooltip
                title={
                    <IncidentInfoCard incidentId={incidentId}/>
                }
                arrow
                placement={"top"}
                disableInteractive
            >
                <a href={`/incident/${incidentId}`}>
                    Incident #{incidentId}
                </a>
            </Tooltip>
        }

        const userActions: { [key: string]: React.ReactElement[] } = {};
        digest.curation_summary.merges.forEach(merge => {
            userActions[merge.user_email] = userActions[merge.user_email] || [];
            userActions[merge.user_email].push(
                <span>
                    <span>User {merge.user_email} merged incidents </span>
                    <span>{merge.incident_ids.join(', ')}</span>
                    <span> into </span>
                    <span>{getIncidentLink(merge.merged_into)}</span>
                </span>
            );
        });
        digest.curation_summary.splits.forEach(split => {
            userActions[split.user_email] = userActions[split.user_email] || [];
            userActions[split.user_email].push(
                <span>
                    <span>User {split.user_email} split reports </span>
                    <span>{split.incident_report_ids.join(', ')}</span>
                    <span> from incident </span>
                    <span>{getIncidentLink(split.from_incident_id)}</span>
                    <span> into incident </span>
                    <span>{getIncidentLink(split.to_incident_id)}</span>
                </span>
            );
        });
        digest.curation_summary.status_changes.forEach(statusChange => {
            userActions[statusChange.user_email] = userActions[statusChange.user_email] || [];
            userActions[statusChange.user_email].push(
                <span>
                    <span>User {statusChange.user_email} set status of incident</span>
                    <span>{getIncidentLink(statusChange.incident_id)}</span>
                    <span>to </span><span>{statusChange.new_status}</span>
                </span>
            );
        });

        const userSections = Object.entries(userActions).map(([userEmail, actions], index) =>
            <span key={index}>{createAccordionSection(
                <Typography variant="h6">{userEmail}</Typography>,
                <Stack gap={1} direction={"column"}>
                    {actions.map((action, index) => <span key={index}>{action}</span>)}
                </Stack>
            )}</span>
        );

        const scrapingSummary =
            <Stack direction={"row"} gap={1}>
                <Typography><b>Scraping</b></Typography>
                <Stack direction={"row"} gap={1} divider={<Divider orientation={"vertical"}/>}>
                    <Typography>{digest.scraping_summary.job_ids.length} scraping jobs</Typography>
                    <Typography>{digest.scraping_summary.source_summary.length} sources scraped</Typography>
                    <Typography>{digest.scraping_summary.source_summary.reduce(
                        (acc, source) => acc + source.extracted, 0
                    )} posts found</Typography>
                    <Typography>{digest.scraping_summary.source_summary.reduce(
                        (acc, source) => acc + source.analyzed, 0
                    )} posts downloaded and analyzed</Typography>
                    <Typography>{digest.scraping_summary.source_summary.reduce(
                        (acc, source) => acc + source.yielded_incidents, 0
                    )} posts yielded incident reports</Typography>
                    <Typography>{digest.scraping_summary.source_summary.reduce(
                        (acc, source) => acc + source.errors.length, 0
                    )} errors</Typography>
                </Stack>
            </Stack>;

        const sourceScrapingSummary = (
            <Box>
                {digest.scraping_summary.source_summary.map((source, index) => (
                    <Box key={index} sx={{marginBottom: 2}}>
                        <Typography variant="h6"><a href={`/sources/${source?.source?.id}`}>{source?.source?.title}</a></Typography>
                        <Typography>Extracted: {source.extracted} posts</Typography>
                        <Typography>Screened using keywords: {source.screened_keywords} posts</Typography>
                        <Typography>Screened using AI: {source.screened_ai} posts</Typography>
                        <Typography>Downloaded: {source.downloaded} posts</Typography>
                        <Typography>Parsed: {source.parsed} posts</Typography>
                        <Typography>Analyzed: {source.analyzed} posts</Typography>
                        <Typography>Outcome: {source.yielded_incidents} posts yielded relevant incidents</Typography>
                        {source.errors.length ? createAccordionSection(
                            <Typography variant={"h6"}>Errors ({source.errors.length})</Typography>,
                            createListSection(source.errors.map(e => e.error || 'Unknown error'))
                        ) : <Typography>No Errors</Typography>}
                    </Box>
                ))}
            </Box>
        );

        const incidentsSummary = (
            <Box>
                {
                    createAccordionSection(
                        <Typography>New Incidents
                            ({digest.incidents_summary.new_incidents_preview.length})</Typography>,
                        createListSection(digest.incidents_summary.new_incidents_preview.map(incident => `ID: ${incident.id}, Summary: ${incident.summary}`))
                    )
                }
                {
                    createAccordionSection(
                        <Typography>Incidents with New Reports
                            ({digest.incidents_summary.incidents_with_new_reports_preview.length})</Typography>,
                        createListSection(digest.incidents_summary.incidents_with_new_reports_preview.map(incident => `ID: ${incident.id}, Summary: ${incident.summary}`))
                    )
                }
            </Box>
        );

        return (
            <Box>
                {createAccordionSection(
                    scrapingSummary,
                    sourceScrapingSummary
                )}
                {createAccordionSection(
                    <Stack direction={"row"} gap={1}>
                        <Typography><b>Incidents</b></Typography>
                        <Stack direction={"row"} gap={1} divider={<Divider orientation={"vertical"}/>}>
                            <Typography>{digest.incidents_summary.new_incidents_count} new incidents</Typography>
                            <Typography>{digest.incidents_summary.incidents_with_new_reports_count} incidents with new
                                reports</Typography>
                        </Stack>
                    </Stack>,
                    incidentsSummary
                )}
                {createAccordionSection(
                    <Stack direction={"row"} gap={1}>
                        <Typography><b>Curation</b></Typography>
                        <Stack direction={"row"} gap={1} divider={<Divider orientation={"vertical"}/>}>
                            <Typography>{digest.curation_summary.merges.length} merges</Typography>
                            <Typography>{digest.curation_summary.splits.length} splits</Typography>
                            <Typography>{digest.curation_summary.status_changes.length} status changes</Typography>
                        </Stack>
                    </Stack>,
                    <Box>
                        <Typography variant={"h6"}>{Object.keys(userActions).length} Active Users</Typography>
                        {userSections}
                    </Box>
                )}
            </Box>
        );
    };

    render() {
        const {coverFrom, coverTo, digest, digestLoading} = this.state;

        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack
                    gap={2} direction={"column"}
                    alignItems={"center"} divider={<Divider orientation={"horizontal"} variant={"middle"}/>}
                >
                    <Typography variant={"h4"}>System Activity Summary</Typography>
                    <Stack gap={1} direction={"row"} alignItems={"center"} justifyContent={"center"}>
                        <DatePicker
                            label="Cover From"
                            value={coverFrom}
                            onChange={this.handleDateChange('coverFrom')}
                            format={"DD/MM/YYYY"}
                            views={['year', 'month', 'day']}
                        />
                        <Typography>to</Typography>
                        <DatePicker
                            label="Cover To"
                            value={coverTo}
                            onChange={this.handleDateChange('coverTo')}
                            format={"DD/MM/YYYY"}
                            views={['year', 'month', 'day']}
                        />
                    </Stack>
                    {digestLoading ? <CircularProgress/> : (digest && (
                        <Paper>
                            {this.renderDigest(digest)}
                        </Paper>
                    ))}
                </Stack>
            </LocalizationProvider>
        );
    }
}

export default SystemDigest;