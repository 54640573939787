import React, {Component} from 'react';
import {Button, Slide, Snackbar, SnackbarContent, Stack} from '@mui/material';
import {
    IIncidentMergeNotification,
    INCIDENT_ACTIONS,
    IIncidentSplitNotification,
    IIncidentStatusChangeNotification, Notification, NOTIFICATION_TYPE,
    NotificationContext
} from './NotificationsContext';
import {setIncidentApprovalStatus, undoMerges} from "../../services/entities/incidents/incidentManager";
import withNotifications, {WithNotificationProps} from "./withNotifications";
import withRouter, {IRouterProps} from "../../services/withRouter";
import {Check} from "@mui/icons-material";

type IProps = {} & WithNotificationProps & IRouterProps;

class NotificationsDisplay extends Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }

    renderNotification = (notification: Notification,
                          removeNotification: (id: number) => void) => {
        const actions: JSX.Element[] = []
        const notificationType = notification?.actionType;
        let notificationColor = "auto"
        let autoHideDuration = notification?.autoHideDuration;
        let title = null;
        if (notification === null) {
            return null
        }
        if (notification.notificationType === NOTIFICATION_TYPE.INCIDENT_ACTION) {
            switch (notificationType) {
                case INCIDENT_ACTIONS.MERGE:
                    const notificationAsMerge = (notification as IIncidentMergeNotification);
                    actions.push(<Button
                        onClick={async () => {
                            await undoMerges(
                                notificationAsMerge.data.merge_action_ids,
                                this.props.notificationContext,
                                notificationAsMerge.undoSuccessCallback,
                                notificationAsMerge.undoErrorCallback
                            );
                        }}
                    >
                        Undo
                    </Button>);
                    title = "" + notificationAsMerge.data.merged.length + " incidents merged"
                    break;
                case INCIDENT_ACTIONS.SPLIT:
                    const notificationAsSplit = (notification as IIncidentSplitNotification);
                    // actions.push(<Button>Undo</Button>); TODO: implement undo split
                    actions.push(
                        <Button
                            onClick={(e) => {
                                this.goToIncidentPage(notificationAsSplit.data.split_into);
                            }}
                            component="a"
                            href={"/incident/" + notificationAsSplit.data.split_into}
                        >
                            Incident #{notificationAsSplit.data.split_into}
                        </Button>
                    )
                    title = "Incident reports split into "
                    break;
                case INCIDENT_ACTIONS.SAVE:
                    // actions.push(<Button>Undo</Button>); TODO: implement undo save
                    title = <Stack direction={"row"} gap={1} alignItems={"center"}>
                        <Check/>
                        <span>Incident saved</span>
                    </Stack>
                    autoHideDuration = autoHideDuration || 3000;
                    break;
                case INCIDENT_ACTIONS.STATUS_CHANGE:
                    const notificationDetails = (notification as IIncidentStatusChangeNotification);
                    actions.push(<Button
                        onClick={async () => {
                            await setIncidentApprovalStatus(
                                notificationDetails.data.prev_status,
                                this.props.notificationContext,
                                undefined, undefined
                            );
                            if (notificationDetails.id) {
                                this.props.notificationContext.removeNotification(notificationDetails.id);
                            }
                            notificationDetails.onUndo(
                                notificationDetails.data
                            )
                        }}
                    >
                        Undo
                    </Button>)
                    title = "Switched the status of " +
                        (notification as IIncidentStatusChangeNotification).data.incident_ids.length +
                        " incidents"
                    break;
                default:
                    break;
            }
        } else if (notification.notificationType === NOTIFICATION_TYPE.ERROR) {
            title = notification.title;
        } else if (notification.notificationType === NOTIFICATION_TYPE.SAVE) {
            notificationColor = "green";
            title = <Stack direction={"row"} gap={1} alignItems={"center"}>
                <Check/>
                <span>{notification.title || "Data Saved"}</span>
            </Stack>
            autoHideDuration = autoHideDuration || 3000;
        } else if (notification.notificationType === NOTIFICATION_TYPE.EMAIL) {
            notificationColor = "auto";
            title = <Stack direction={"row"} gap={1} alignItems={"center"}>
                <Check/>
                <span>{notification.title || "Email Sent"}</span>
            </Stack>
            autoHideDuration = autoHideDuration || 3000;
        }
        return notification ? <Snackbar
            anchorOrigin={{vertical: "bottom", horizontal: "right"}}
            dir={"rtl"}
            key={notification?.id}
            open
            autoHideDuration={autoHideDuration}
            onClose={() => {
                if (notification && notification.id !== undefined) {
                    removeNotification(notification.id)
                }
            }}
            TransitionComponent={(props) => <Slide {...props} direction="up" />}
        >
            <SnackbarContent
                style={{ backgroundColor: notificationColor }}
                dir={"ltr"}
                message={
                    <Stack direction={"row"} gap={1} dir={"ltr"} alignItems={"center"}>
                        <span>{title}</span>
                        {
                            actions.map((a, i) => {
                                return <React.Fragment key={i}>
                                    {a}
                                </React.Fragment>
                            })
                        }
                    </Stack>
                }
            />
        </Snackbar> : null
    }

    private goToIncidentPage(incidentId: number) {
        this.props.navigate({
            pathname: "/incident/" + incidentId,
        });
    }

    render() {
        return (
            <NotificationContext.Consumer>
                {(context) => {
                    if (!context) {
                        return null; // or some fallback UI
                    }
                    const {notifications, removeNotification} = context;
                    return (
                        <>
                            {notifications.map((notification: Notification) => {
                                    if (!notification) {
                                        return null
                                    }
                                    return this.renderNotification(notification, removeNotification)
                                }
                            )}
                        </>
                    )
                }}
            </NotificationContext.Consumer>
        );
    }
}

export default withRouter(withNotifications<IProps>(NotificationsDisplay));
