import React from 'react'
import TopNavBar from '../UIComponents/TopNavBar/TopNavBar';
import withRouter, {IRouterProps} from "../services/withRouter";
import IncidentBrowser from "./incident_browser/IncidentBrowser";
import {
    browseToCurationWorkspace, encodeBrowseWorkspaceSearchParams, encodeCurationSessionWorkspaceSearchParams,
    IBrowseWorkspace,
    parseBrowseWorkspaceSearchParams
} from "../services/types/workspace";
import {Button, Stack} from "@mui/material";
import {History} from "@mui/icons-material";
import {t} from "@lingui/core/macro";

interface IProps extends IRouterProps {}
interface IState {
    initWorkspace: IBrowseWorkspace,
    storedWorkspace: IBrowseWorkspace | null,
    suppressReload: boolean
}


class BrowseIncidents extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const workspaceParamStr = props.searchParams.get("s");
        const initWorkspace= parseBrowseWorkspaceSearchParams(workspaceParamStr);
        const storedWorkspaceRison = localStorage.getItem("browse_workspace");
        const storedWorkspace = (storedWorkspaceRison && storedWorkspaceRison !== workspaceParamStr) ?
            parseBrowseWorkspaceSearchParams(storedWorkspaceRison) :
            null;
        this.state = {
            initWorkspace,
            storedWorkspace,
            suppressReload: false
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const workspaceParamStr = this.props.searchParams.get("s");
        if(workspaceParamStr !== prevProps.searchParams.get("s")){
            this.setState({
                initWorkspace: parseBrowseWorkspaceSearchParams(workspaceParamStr),
                suppressReload: true
            })
        } else{
            if(this.state.suppressReload) {
                this.setState({
                    suppressReload: false
                })
            }
        }
    }

    private goToIncidentPage = (incidentId:number) => {
        this.props.navigate({
            pathname: "/incident/" + incidentId
        });
    }

    private setWorkspaceConfig = (workspace: IBrowseWorkspace) => {
        const encodedWorkspace = encodeBrowseWorkspaceSearchParams(workspace);
        localStorage.setItem("browse_workspace", encodedWorkspace)
        const newUrl = `${window.location.pathname}?s=${encodedWorkspace}`;
        const currentUrl = window.location.href;
        this.setState({suppressReload: true}, () => {
            if (newUrl !== currentUrl) {
                window.history.pushState({}, '', newUrl);
            }
        })
    }

    private dismissSavedWorkspace = () => {
        this.setState({storedWorkspace: null})
    }

    private loadSavedWorkspace = () => {
        const storedWorkspace = this.state.storedWorkspace
        if(!storedWorkspace){
            return;
        }
        this.setState({initWorkspace: JSON.parse(JSON.stringify(storedWorkspace)), storedWorkspace: null})
    }

    render() {
        return <div className={"page-wrap"}>
            <TopNavBar>
                <Stack direction={"row"} alignItems={"center"} gap={2} justifyContent={"start"}>
                    <div className="title-wrap">
                        {t`Incidents`}
                    </div>
                    {
                        this.state.storedWorkspace && !(window.innerWidth <= 768) ?
                            <Button
                                startIcon={<History/>}
                                variant={"outlined"}
                                onClick={this.loadSavedWorkspace}
                            >
                                {t`Load Filters From Previous Session`}
                            </Button> :
                            null
                    }
                </Stack>
            </TopNavBar>
            <div className={"page-content content-wrap"}>
            <IncidentBrowser
                    initWorkspace={this.state.initWorkspace}
                    onWorkspaceChange={(workspace)=>{
                        this.setWorkspaceConfig(workspace)
                    }}
                    onClick={this.goToIncidentPage}
                />
            </div>
        </div>
    }
}

export default (withRouter(BrowseIncidents));