import React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,
    Paper,
    Stack,
    Tooltip,
    Zoom
} from "@mui/material";
import {AddCircle, ExpandMore, RemoveCircle} from "@mui/icons-material";
import {DataItem, DataQuery, IDataField} from "../../services/entities/entityFields";
import {Entity} from "../../services/entities/entityBase";
import {TransparentTooltip} from "../../UIComponents/StyledComponents/TransparentTooltip";

interface IProps<ItemType extends DataItem, QueryType extends DataQuery<ItemType>> {
    entity: Entity<ItemType, QueryType>;
    readOnly?: boolean;
    data: ItemType[];
    editData: (row: number, field: string, value: any) => void;
    deleteData: (rowIndex: number) => void;
}

class EntitiesEditor<ItemType extends DataItem, QueryType extends DataQuery<ItemType>>
    extends React.Component<IProps<ItemType, QueryType>> {
    fields: IDataField<any>[] = this.props.entity.fields;
    accordionHeaderGenerator = this.props.entity.accordionHeader;

    accordionBodyGenerator = (itemData: ItemType, itemUnPaginatedIndex: number) => {
        const fields: IDataField<ItemType>[] = this.props.entity.fields;
        return <Stack direction={"column"} gap={1} alignItems={"left"} dir={"ltr"}>
            {
                fields
                    .filter((f) => !f.hideWhen || !f?.hideWhen(itemData))
                    .map((f) => this.props.entity.DataFieldRenderer[f.field_type](
                        f,
                        itemData[f.key as keyof ItemType],
                        (value) => {
                            if(!this.props.readOnly) {
                                this.props.editData(itemUnPaginatedIndex, f.key, value);
                            }
                        }
                    ))
            }
        </Stack>
    };


    private addRow() {
        if(this.props.readOnly){return}
        const data = this.props.data.slice();
        data.push({
            ...JSON.parse(JSON.stringify(this.props.entity.defaultNewItem)),
            id: null,
            _changed: true
        });
        this.setState({data});
    }

    render() {
        const {data, deleteData} = this.props;
        return (
            <Stack direction={"column"} gap={1} className={"settings-editor"}>
                {data.map((r, p_i) => {
                    const i: number = p_i;
                    return (
                        <React.Fragment key={i}>
                            <TransparentTooltip
                                TransitionComponent={Zoom}
                                TransitionProps={{style: {transformOrigin: "center left"}}}
                                title={
                                    !this.props.readOnly ? <Tooltip title={"Remove record"} arrow placement={"bottom"}>
                                        <IconButton
                                            aria-label="delete"
                                            color={"error"}
                                            onClick={() => {
                                                deleteData(i);
                                            }}
                                        >
                                            <RemoveCircle/>
                                        </IconButton>
                                    </Tooltip> : null
                                }
                                placement={"right-start"}
                            >
                                {this.accordionHeaderGenerator ? (
                                    <Accordion disableGutters>
                                        <AccordionSummary
                                            expandIcon={<ExpandMore/>}
                                            sx={{'& .MuiAccordionSummary-content': {maxWidth: 'calc(100% - 2em)'}}}
                                        >
                                            {this.accordionHeaderGenerator(r)}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {this.accordionBodyGenerator(r, i)}
                                        </AccordionDetails>
                                    </Accordion>
                                ) : (
                                    <Paper>
                                        {this.accordionBodyGenerator(r, i)}
                                    </Paper>
                                )}
                            </TransparentTooltip>
                        </React.Fragment>
                    );
                })}
                {
                    !this.props.readOnly && <div>
                        <Tooltip title={"Add record"} arrow placement={"bottom"}>
                            <IconButton
                                aria-label="add"
                                color={"primary"}
                                onClick={() => {
                                    this.addRow();
                                }}
                            >
                                <AddCircle/>
                            </IconButton>
                        </Tooltip>
                    </div>
                }
            </Stack>
        );
    }
}

export default EntitiesEditor;