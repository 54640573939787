import React from 'react';
import './TopNavBar.scss';
import server from '../../services/server';
import cookie from 'js-cookie';

import MenuIcon from '@mui/icons-material/Menu';
import withRouter from "../../services/withRouter";
import {encodeBrowseWorkspaceSearchParams, getDefaultBrowseWorkspace} from "../../services/types/workspace";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import {t} from "@lingui/core/macro";

interface IProps {
    location: any,
    navigate: any,
    params: any
    searchParams: any
    setSearchParams: any
    justification?: string
    children: JSX.Element
}

interface IState {
    emoji: string,
    menuOpened: boolean,
    permissions: any
}

export default withRouter(class TopNavBar extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            menuOpened: false,
            emoji: "🌹",
            permissions: {}
        };
    }

    componentDidMount() {
        this.setState((curr) => ({...curr, permissions: this.getPermissions()}))
    }

    getPermissions() {
        const json = cookie.get("permissions");
        if (!json)
            return {};
        try {
            return JSON.parse(json);
        } catch (e) {
            return {}
        }
    }

    logout() {
        server.get('login/logout/', {})
            .then(() => {
                const cookies = cookie.get();
                Object.keys(cookies).forEach((cookieName) => {
                    cookie.remove(cookieName);
                })
                this.props.navigate('/Login')
            });
    }

    toggleMenu = () => {
        if (!this.state.menuOpened) {
            const possibleMenuEmojis = ["🌹"];
            const emoji = possibleMenuEmojis[Math.floor(Math.random() * possibleMenuEmojis.length)];
            this.setState((curr) => ({...curr, menuOpened: true, emoji: emoji}));
        } else {
            this.setState((curr) => ({...curr, menuOpened: false}));
        }
    }

    goToPage = (page: string, search?: { [key: string]: any }) => {
        this.props.navigate({
            pathname: "/" + page,
            search: search ? Object.keys(search).map(k => "" + k + "=" + search[k]).join("&") : ""
        });
        this.toggleMenu();
    }

    getMenuItem(page: string, label: string, search?: { [key: string]: any }) {
        return (
            <a
                href={"/" + page}
                className="sidebar-item button"
                onClick={(e) => {
                    this.goToPage(page, search || {})
                    e.preventDefault()
                }}
            >
                <div>{label}</div>
            </a>
        );
    }

    render() {
        return (
            <div className="nav-bar-wrapper" style={{"justifyContent": (this.props.justification || "end")}}>
                <div className="nav-bar">
                    <div className={"nav-bar-logo-menu-wrap " + (this.state.menuOpened ? "sidebar-open" : "")}>
                        <div className="nav-bar-icon" onClick={this.toggleMenu}>
                            <MenuIcon/>
                        </div>
                        <div className="sidebar-background" onClick={this.toggleMenu}/>
                        <div className={"sidebar-wrap"}>
                            <div className={"sidebar"}>
                                <div className="sidebar-title">
                                    {this.state.emoji}
                                </div>
                                <div className="sidebar-options">
                                    {this.getMenuItem("incidents", t`Incidents For Curation`, {"s": encodeBrowseWorkspaceSearchParams(getDefaultBrowseWorkspace(["not approved"]))})}
                                    {this.getMenuItem("incidents", t`Approved Incidents`, {"s": encodeBrowseWorkspaceSearchParams(getDefaultBrowseWorkspace(["approved"]))})}
                                    {this.getMenuItem("incidents", t`Irrelevant Incidents`, {"s": encodeBrowseWorkspaceSearchParams(getDefaultBrowseWorkspace(["removed"]))})}
                                    {this.getMenuItem("charts", t`Data`)}
                                    {this.getMenuItem("system-snapshots", t`System Snapshots`)}
                                    <Accordion disableGutters>
                                        <AccordionSummary>
                                            <div className="sidebar-item">
                                                <b>{t`Settings`}</b>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {this.getMenuItem("sources", t`Sources`)}
                                            {this.getMenuItem("keywords", t`Inclusion Keywords`)}
                                            {this.getMenuItem("exclusion-keywords", t`Exclusion Keywords`)}
                                            {this.getMenuItem("ai-screening", t`AI Screening`)}
                                            {this.getMenuItem("null-flags", t`Missing Data Flags`)}
                                            {this.getMenuItem("columns", t`Incident Structure`)}
                                            {this.getMenuItem("users", t`Users`)}
                                            {this.getMenuItem("scrape", t`Manual Scraping`)}
                                            {this.getMenuItem("preferences", t`Preferences`)}
                                        </AccordionDetails>
                                    </Accordion>
                                    <div
                                        className="sidebar-item button"
                                        onClick={(e) => {
                                            this.logout()
                                        }}
                                    >
                                        <div>{t`Logout`}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.props.children}
                </div>
            </div>
        )
    }


})
