import React from 'react'
import {
    Accordion, AccordionDetails, AccordionSummary,
    Button,
    Card,
    CircularProgress, Divider, Fab, FormControl, Grow, Input,
    Stack, Tooltip,
} from "@mui/material";
import server from "../../services/server";
import {Add, Archive, Edit, HistoryEdu, Send, Sms} from "@mui/icons-material";
import {cn} from "../../services/utils";
import "./IncidentComments.scss"
import {getTextDirection} from "../../services/multiLangUI";
import { TransitionGroup } from 'react-transition-group';
import {Trans} from "@lingui/react/macro";
import {t} from "@lingui/core/macro";

interface IProps {
    incidentId: number,
}

interface ICommentThread {
    id?: number;
    user_id?: number;
    incident_id: number;
    create_date?: Date;
    update_date?: Date;
    state: "open" | "approved" | "dismissed";
    resolved_by?: number;
    comments?: IComment[];
}


interface IComment {
    id?: number;
    user_id?: number;
    username?: string;
    thread_id?: number;
    create_date?: Date;
    update_date?: Date;
    comment: string;
    editState: "read" | "edit" | "saving";
}

interface IState {
    threads: ICommentThread[] | null,
    loadingComments: boolean,
    newComment: string,
    myId: number | null
}

class IncidentComments extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            threads: null,
            loadingComments: false,
            newComment: "",
            myId: null
        }
    }

    async componentDidMount() {
        await this.fetchComments()
    }

    async componentDidUpdate(prevProps: Readonly<IProps>) {
        if (this.props.incidentId !== prevProps.incidentId) {
            this.setState((curr) => ({...curr, threads: null}), async () => {
                await this.fetchComments()
            })
        }
    }

    private async fetchComments() {
        if (this.state.loadingComments) {
            return
        }
        this.setState((curr) => ({...curr, loadingComments: true}), async () => {
            const res = await server.get(
                "incident/comment/" + this.props.incidentId
            );
            const thread_dicts = res.threads;
            const myId = res.my_id;
            const threads = thread_dicts.map((x: any) => {
                const comments = x.comments.map((y: any) => {
                    return {
                        ...y,
                        create_date: new Date(y.create_date),
                        editState: "read"
                    } as IComment
                });
                return {
                    ...x,
                    comments,
                } as ICommentThread
            })
            this.setState((curr) => ({...curr, threads, myId, loadingComments: false}))
        })
    }

    private async saveComment(thread_index: number, comment_index: number) {
        const threads = this.state.threads?.slice() || [];
        if (threads.length <= thread_index) {
            return
        }
        const thread = threads[thread_index];
        const comments = thread.comments;
        if (!comments || comments.length <= comment_index || comments[comment_index].editState === "saving") {
            return
        }
        const comment = comments[comment_index];
        if (comment.comment.length === 0) {
            return
        }
        comment.editState = "saving";
        this.setState((curr) => ({...curr, threads}), async () => {
            const res = await server.post(
                "incident/comment/",
                {incident_comment: comment, incident_id: this.props.incidentId}
            );
            if (res) {
                thread.id = res.thread_id;
                const newComment = res;
                newComment.create_date = new Date(newComment.create_date);
                newComment.editState = "read";
                comments[comment_index] = newComment;
                this.setState((curr) => ({...curr, threads}))
            }
        })
    }

    set_thread_status(thread_index: number, status: "open" | "approved" | "dismissed") {
        const threads = this.state.threads?.slice() || [];
        if (threads.length <= thread_index) {
            return
        }
        const thread = threads[thread_index];
        if (thread.state === status) {
            return
        }
        thread.state = status;
        this.setState((curr) => ({...curr, threads}), async () => {
            const res = await server.post(
                "incident/comment/status/",
                {
                    incident_thread_id: thread.id,
                    status
                }
            );
            if (res) {
                this.setState((curr) => ({...curr, threads}))
            }
        })
    }

    addThread = () => {
        const threads = this.state.threads?.slice() || [];
        threads.push({
            user_id: undefined,
            incident_id: this.props.incidentId,
            state: "open",
            comments: [
                {
                    user_id: this.state.myId || undefined,
                    comment: this.state.newComment,
                    editState: "edit"
                }
            ]
        });
        this.setState({threads, newComment: ""}, async () => {
            await this.saveComment(threads.length - 1, 0);
        });
    }

    renderThreads = (threadsFilter: (t: ICommentThread) => boolean) => {
        const threads = this.state.threads?.slice() || [];
        const now = new Date();
        return <Stack
            direction={"column"}
            gap={2}
            alignItems={"start"}
            justifyContent={"start"}
            className={"threads-list"}
        ><TransitionGroup component={null}>
            {
                threads.map((t, i)=>({t, i})).filter(({t})=>threadsFilter(t)).map(({t, i}) => {
                    return (
                        <Grow key={t.id || "new_thread_" + i}>
                            <Card

                                className={cn({
                                    "thread-card": true,
                                    "approved-thread": t.state === "approved",
                                    "dismissed-thread": t.state === "dismissed"
                                })}
                            >
                                <Stack direction="column" spacing={2}>
                                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                                        {t.state === "open" && (
                                            <>
                                                <Button
                                                    onClick={() => this.set_thread_status(i, "approved")}
                                                    variant="outlined"
                                                    color="success"
                                                    className={"thread-status-change-button"}
                                                >
                                                    <Trans>Issue Resolved</Trans>
                                                </Button>
                                                <Button
                                                    onClick={() => this.set_thread_status(i, "dismissed")}
                                                    variant="outlined"
                                                    color="error"
                                                    className={"thread-status-change-button"}
                                                >
                                                    <Trans>Issue Dismissed</Trans>
                                                </Button>
                                            </>
                                        )}
                                        {t.state !== "open" && (
                                            <Button
                                                onClick={() => this.set_thread_status(i, "open")}
                                                variant="outlined"
                                                className={"thread-status-change-button"}
                                            >
                                                <Trans>Reopen Issue</Trans>
                                            </Button>
                                        )}
                                    </Stack>
                                    <Divider/>
                                    <Stack direction="column" spacing={2} className={"thread-comments-wrap"}>
                                        {
                                            t.comments?.sort((a, b)=> {
                                                return (a.create_date?.getTime() || now.getTime()) - (b.create_date?.getTime()|| now.getTime())
                                            })?.map((c, j) => {
                                                const myComment = c.user_id === this.state.myId;
                                                return (
                                                    <Stack
                                                        key={c.id || "new_comment_" + i + "_" + j}
                                                        className={cn({
                                                            "comment-card": true,
                                                            "my-comment": myComment,
                                                            "other-comment": !myComment,
                                                            "edit-comment": c.editState === "edit",
                                                        })}
                                                    >
                                                        <Stack direction="row" spacing={2} className={"comment-header"}>
                                                            <Stack direction="row" spacing={1}>
                                                                <div className={"comment-author"}>
                                                                    {myComment ? "me" : (c.username || "").split("@")[0]}
                                                                </div>
                                                                <div className={"comment-date"}>
                                                                    {c.create_date?.toISOString().split("T")[0]}
                                                                </div>
                                                            </Stack>
                                                            <div>
                                                                {c.editState === "read" && myComment && (
                                                                    <Tooltip title={"Edit Comment"} placement={"top"}
                                                                             arrow>
                                                                        <button
                                                                            onClick={() => {
                                                                                c.editState = "edit";
                                                                                this.setState({threads});
                                                                            }}
                                                                        >
                                                                            <Edit color="primary" fontSize={"small"}/>
                                                                        </button>
                                                                    </Tooltip>
                                                                )}
                                                            </div>
                                                        </Stack>
                                                        {c.editState === "edit" ? (
                                                            <FormControl fullWidth>
                                                                <Input
                                                                    multiline
                                                                    value={c.comment}
                                                                    onChange={(e) => {
                                                                        c.comment = e.target.value;
                                                                        this.setState({threads});
                                                                    }}
                                                                    dir={getTextDirection(c.comment)}
                                                                    onKeyDown={async (e) => {
                                                                        if (e.key === "Enter" && !e.shiftKey) {
                                                                            await this.saveComment(i, j);
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        ) : (
                                                            <div
                                                                dir={getTextDirection(c.comment)}
                                                                style={{
                                                                    textAlign: getTextDirection(c.comment) === "rtl" ? "right" : "left",
                                                                }}
                                                            >
                                                                {c.comment}
                                                            </div>
                                                        )}
                                                    </Stack>
                                                )
                                            })
                                        }
                                        {t.state === "open" && <Stack direction={"row"} justifyContent={"center"}>
                                            <Tooltip title={"Add Comment"} arrow placement={"top"}>
                                                <Fab
                                                    size={"small"}
                                                    onClick={() => {
                                                        t.comments?.push({
                                                            user_id: this.state.myId || undefined,
                                                            comment: "",
                                                            editState: "edit",
                                                            thread_id: t.id,
                                                            create_date: new Date()
                                                        });
                                                        this.setState({threads});
                                                    }}
                                                    color={"primary"}
                                                >
                                                    <Add/>
                                                </Fab>
                                            </Tooltip>
                                        </Stack>}
                                    </Stack>
                                </Stack>
                            </Card>
                        </Grow>
                    )
                })
            }</TransitionGroup>
        </Stack>
    }

    render() {
        const threads = this.state.threads?.slice() || [];
        const openThreadsCount = threads.filter((t) => t.state === "open").length;
        const resolvedThreadsCount = threads.filter((t) => t.state !== "open").length;
        return <div className={"incidents-comments-wrap"}>
            {
                this.state.loadingComments ?
                    <CircularProgress/> :
                    <Stack
                        direction={"column"}
                        gap={2}
                        alignItems={"start"}
                        justifyContent={"start"}
                        className={"comments-section-wrap"}
                    >
                        {!!openThreadsCount && <Accordion
                            defaultExpanded disableGutters className={"threads-area-accordion"}
                        >
                            <AccordionSummary>
                                <Stack
                                    direction={"row"}
                                    gap={2}
                                    alignItems={"center"}
                                >
                                    <Sms/>
                                    <div className="title-wrap">
                                        <Trans>Open Threads ({openThreadsCount})</Trans>
                                    </div>
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                {this.renderThreads((t) => t.state === "open")}
                            </AccordionDetails>
                        </Accordion>}
                        <Stack
                            direction={"row"}
                            gap={2}
                            alignItems={"center"}
                        >
                            <HistoryEdu/>
                            <div className="title-wrap">
                                <Trans>New Thread</Trans>
                            </div>
                        </Stack>
                        <FormControl fullWidth>
                            <Input
                                dir={this.state.newComment.length ? getTextDirection(this.state.newComment) : "inherit"}
                                multiline
                                value={this.state.newComment}
                                onChange={(e) => {
                                    this.setState({newComment: e.target.value});
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter" && !e.shiftKey) {
                                        this.addThread();
                                        e.preventDefault();
                                    }
                                }}
                                endAdornment={
                                    <Tooltip
                                        title={this.state.newComment.length === 0 ? null : t`Add comment`}
                                        arrow
                                        placement={"top"}
                                    >
                                        <span>
                                            <Fab
                                                onClick={this.addThread}
                                                disabled={this.state.newComment.length === 0}
                                                color={"primary"}
                                                size={"small"}
                                            >
                                                <Send fontSize={"small"}/>
                                            </Fab>
                                        </span>
                                    </Tooltip>
                                }
                            />
                        </FormControl>
                        {!!resolvedThreadsCount && <Accordion disableGutters className={"threads-area-accordion"}>
                            <AccordionSummary>
                                <Stack
                                    direction={"row"}
                                    gap={2}
                                    alignItems={"center"}
                                >
                                    <Archive/>
                                    <div className="title-wrap">
                                        <Trans>Resolved Threads ({resolvedThreadsCount})</Trans>
                                    </div>
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                {this.renderThreads((t) => t.state !== "open")}
                            </AccordionDetails>
                        </Accordion>}
                    </Stack>
            }
        </div>
    }
}

export default IncidentComments;