import React, {createContext, Component} from 'react';
import {I18nProvider} from '@lingui/react';
import {i18n} from '@lingui/core';
import {messages as enMessages} from '../../locales/en/messages';
import {messages as heMessages} from '../../locales/he/messages';
import {messages as arMessages} from '../../locales/ar/messages';

i18n.load({
  en: enMessages,
  he: heMessages,
  ar: arMessages,
});

const loadMessages = async (locale: string) => {
    i18n.activate(locale);
};

export type Language = 'en' | 'he' | 'ar';

interface LangProperties {
    dir: "ltr" | "rtl",
    title: string,
    font?: string,
    dateFormat?: string
}

export const langProperties: { [key in Language]: LangProperties } = {
    "en": {dir: "ltr", title: "English"},
    "he": {dir: "rtl", title: "Hebrew"},
    "ar": {dir: "rtl", title: "Arabic"}
}

interface LanguageContextProps {
    language: Language;
    setLanguage: (language: Language) => void;
}

export const LanguageContext = createContext<LanguageContextProps | undefined>(undefined);

interface LanguageProviderProps {
    children: React.ReactNode;
}

interface LanguageProviderState {
    language: Language;
    messagesLoaded: boolean;
}

export class LanguageProvider extends Component<LanguageProviderProps, LanguageProviderState> {
    state: LanguageProviderState = {
        language: 'he',
        messagesLoaded: false,
    };

    async componentDidMount() {
        const storedLanguage = (localStorage.getItem('language') || 'he') as Language;
        await this.setLanguage(storedLanguage);
    }

    setLanguage = async (language: Language) => {
        this.setState({language, messagesLoaded: false});
        await loadMessages(language);
        this.setState({messagesLoaded: true});
        localStorage.setItem('language', language);
    };

    render() {
        const {children} = this.props;
        const {language, messagesLoaded} = this.state;

        if (!messagesLoaded) {
            return null; // or a loading spinner
        }
        const DefaultI18n = ({children}: { children: any }) => <span>{children}</span>;

        return (
            <LanguageContext.Provider value={{language, setLanguage: this.setLanguage}}>
                <I18nProvider i18n={i18n} defaultComponent={DefaultI18n}>
                    {children}
                </I18nProvider>
            </LanguageContext.Provider>
        );
    }
}