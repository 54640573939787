import React from 'react';
import {Button, CircularProgress, Stack, Step, StepButton, Stepper, Typography} from '@mui/material';
import server from '../../services/server';
import ReactJson from 'react-json-view';
import {IColumnObject} from "../../services/types/columns";
import {IPost} from "../../services/entities/posts";
import ExtendedPostsPicker from "../../UIComponents/ListPicker/ExtendedPostsPicker";
import IncidentEditor from "../incident/IncidentEditor";
import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';

interface IProps {
    columns: IColumnObject | null;
}

interface IState {
    activeStep: number,
    promptPreview: { prompt: string, response_format: any } | null;
    loadingPrompt: boolean;
    selectedPost: IPost | null
    analysisResults: any[] | null,
    loadingAnalysis: boolean;
    analysisError: string | null;
}

class PromptTester extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            activeStep: 0,
            promptPreview: null,
            loadingPrompt: false,
            selectedPost: null,
            analysisResults: null,
            loadingAnalysis: false,
            analysisError: null
        };
    }

    async componentDidMount() {
        await this.fetchPrompt();
    }

    async componentDidUpdate(prevProps: Readonly<IProps>) {
        if (JSON.stringify(prevProps.columns) !== JSON.stringify(this.props.columns)) {
            await this.fetchPrompt();
        }
    }

    fetchPrompt = async () => {
        if (this.props.columns === null) {
            return
        }
        this.setState({loadingPrompt: true});
        const promptPreview = await server.post("columns/prompt_preview/", {columns: JSON.stringify(this.props.columns)});
        this.setState({promptPreview, loadingPrompt: false});
    };

    renderPromptPreview() {
        const {promptPreview, loadingPrompt} = this.state;
        return <Stack
            sx={{
                overflowY: "auto",
                overflowX: "hidden",
                height: "100%",
                width: "100%"
            }}
        > {
            loadingPrompt ? (
                <CircularProgress/>
            ) : (
                <>
                    <div>
                        {(promptPreview?.prompt || "").split("\n").map((p: string, i: number) => (
                            <p key={i}>{p}</p>
                        ))}
                    </div>
                    {promptPreview?.response_format && <ReactJson
                        src={promptPreview.response_format}
                        enableClipboard={false}
                    />}
                </>
            )
        }</Stack>
    }

    private renderPostSelector() {
        const selectedPost = this.state.selectedPost;
        return <ExtendedPostsPicker
            multiple={false}
            selected={selectedPost ? [selectedPost] : []}
            onSelect={(selected) => {
                this.setState((curr) => ({
                    ...curr,
                    selectedPost: selected.length ? selected[0] : null,
                    activeStep: selected.length ? 2 : 1,
                    analysisResults: null
                }), async ()=>{
                    if(this.state.activeStep === 2 && !this.state.analysisResults){
                        await this.runAnalysis()
                    }
                })
            }}
        />
    }

    runAnalysis = async () => {
        if (!this.state.selectedPost) {
            return
        }
        this.setState({loadingAnalysis: true}, async () => {
            const data = await server.post("analyze/post/", {
                post: this.state.selectedPost,
                columns: this.props.columns
            })
            if (data && !("error" in data)) {
                this.setState({analysisResults: data.incidents || [], loadingAnalysis: false, analysisError: null})
            } else {
                this.setState({analysisResults: null, loadingAnalysis: false, analysisError: data.error})
            }
        });
    }

    private renderAnalysisResults() {
        const analysisResults = this.state.analysisResults;
        return <Stack direction={"column"} gap={2} alignItems={"center"}>
            <Button
                variant="contained"
                color="primary"
                onClick={this.runAnalysis}
                disabled={this.state.loadingAnalysis}
            >
                {
                    this.state.loadingAnalysis ? t`Running Analysis...` :
                        (analysisResults ? t`Rerun Analysis` : t`Run Analysis`)
                }
            </Button>
            {this.state.loadingAnalysis && <CircularProgress/>}
            {
                analysisResults && <Stack direction={"column"} gap={1}>
                    {analysisResults.map((incident: any, i: number) => {
                        return <Stack key={i} direction={"column"} gap={1}>
                            <Typography>Incident #{(i + 1)}</Typography>
                            <IncidentEditor
                                data={incident}
                                column={this.props.columns || undefined}
                            />
                        </Stack>
                    })}
                    {analysisResults.length === 0 && <Typography><Trans>No Relevant Incidents Found</Trans></Typography>}
                </Stack>
            }
        </Stack>
    }

    render() {
        const activeStep = this.state.activeStep;
        const selectionSteps: {
            label: string,
            disabled: boolean,
            completed: boolean
        }[] = [
            {label: t`Preview Prompt`, disabled: false, completed: activeStep > 0},
            {label: t`Select Post`, disabled: !this.state.promptPreview, completed: !!this.state.selectedPost},
            {label: t`Analyze`, disabled: !this.state.selectedPost, completed: !!this.state.analysisResults},
        ];

        return (
            <Stack direction={"column"} gap={2} sx={{
                overflowY: "auto",
                overflowX: "hidden",
                height: "100%",
                width: "100%"
            }}>
                <Stepper activeStep={activeStep}>
                    {selectionSteps.map((s, i) => (
                        <Step key={i} disabled={s.disabled} completed={s.completed}>
                            <StepButton
                                color="inherit"
                                onClick={() => {
                                    this.setState((curr) => ({...curr, activeStep: i}))
                                }}
                            >
                                {s.label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === 0 && this.renderPromptPreview()}
                {activeStep === 1 && this.renderPostSelector()}
                {activeStep === 2 && this.renderAnalysisResults()}
            </Stack>
        );
    }
}

export default PromptTester;