import React from 'react';
import TopNavBar from '../UIComponents/TopNavBar/TopNavBar';
import withRouter, {IRouterProps} from "../services/withRouter";
import {defaultQuery} from "./entitiesBrowseAndEdit/EntitiesManager";
import {Entity} from "../services/entities/entityBase";
import {DataItem, DataQuery} from "../services/entities/entityFields";
import rison from "rison";
import BasicEntitiesTable from "./entitiesTable/BasicEntitiesTable";

interface IProps
    extends IRouterProps {
    entity: Entity<DataItem, DataQuery<DataItem>>;
}

interface IState {
    query: DataQuery<DataItem>;
}

class EntitiesBrowser extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const queryArg = props.searchParams.get("s");
        const query = (queryArg === null ? {} : rison.decode(queryArg)) || {};
        this.state = {
            query: {...defaultQuery, ...query}
        };
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        const queryArg = this.props.searchParams.get("s");
        const query = ((queryArg === null ? {} : rison.decode(queryArg)) || {}) as DataQuery<DataItem>;
        const prevQueryArg = prevProps.searchParams.get("s");
        if (queryArg !== prevQueryArg) {
            this.setState((curr) => ({...curr, query}))
        }
    }

    setQuery = (query: DataQuery<DataItem>) => {
        const encodedQuery = rison.encode(query);
        const queryArg = this.props.searchParams.get("s");
        if (queryArg === encodedQuery) {
            return
        }
        const stateQuery = rison.encode(this.state.query);
        if (stateQuery === encodedQuery) {
            return
        }
        const newUrl = `${window.location.pathname}?s=${encodedQuery}`;
        this.setState({query}, ()=>{
            window.history.pushState({}, '', newUrl);
        });
    }

    render() {
        const {entity} = this.props;
        return (
            <div className={"page-wrap"}>
                <TopNavBar>
                    <div className="title-wrap">
                        {entity.title}
                    </div>
                </TopNavBar>
                <div className={"page-content content-wrap"}>
                    <BasicEntitiesTable
                        entity={entity}
                        initQuery={this.state.query}
                        onQueryChange={this.setQuery}
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(EntitiesBrowser)