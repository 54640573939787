import React, { useContext } from 'react';
import { LanguageContext, Language } from './LanguageContext';

export interface ILanguageProps {
  language: Language;
  setLanguage: (language: Language) => void;
}

const withLanguage = <P extends object>(WrappedComponent: React.ComponentType<P & ILanguageProps>) => {
  return (props: Omit<P, keyof ILanguageProps>) => {
    const context = useContext(LanguageContext);

    if (!context) {
      throw new Error('withLanguage must be used within a LanguageProvider');
    }

    const { language, setLanguage } = context;

    return <WrappedComponent {...(props as P)} language={language} setLanguage={setLanguage} />;
  };
};

export default withLanguage;