import React from 'react';
import {Divider, Stack} from '@mui/material';
import {DataItem, DataQuery, IDataField} from '../../services/entities/entityFields';
import {Entity} from "../../services/entities/entityBase";
import {ENTITIES} from "../../services/entities/entities";
import BasicEntitiesTable from "../entitiesTable/BasicEntitiesTable";
import { Trans } from '@lingui/react/macro';

interface IProps<ItemType extends DataItem, QueryType extends DataQuery<ItemType>> {
    entityId: number | null,
    entity: Entity<ItemType, QueryType>;
    data: ItemType;
    setData: (data: ItemType) => void;
}

interface IState {
    relatedEntitiesQueries: DataQuery<any>[]
}

class EntityEditor<ItemType extends DataItem, QueryType extends DataQuery<ItemType>>
    extends React.Component<IProps<ItemType, QueryType>, IState> {
    constructor(props: IProps<ItemType, QueryType>) {
        super(props);
        this.state = {
            relatedEntitiesQueries: []
        };
    }

    getDataEditor() {
        const {entity, data, setData} = this.props;
        const fields: IDataField<any>[] = entity.fields;

        return (
            <Stack direction={'column'} gap={1} alignItems={'left'} dir={'ltr'}>
                {fields.filter(f => !f.hideWhen || !f.hideWhen(data))
                    .map(f => (entity.DataFieldRenderer[f.field_type](
                            f,
                            data[f.key as keyof ItemType],
                            value => {
                                const newData = {...data, [f.key]: value};
                                setData(newData);
                            })
                    ))}
            </Stack>
        );
    }

    render() {
        const {entity, data, setData} = this.props;
        const additionalComponents = entity.additionalComponents;

        return (
            <Stack direction={'column'} gap={1} divider={<Divider orientation="horizontal" flexItem/>}>
                {this.getDataEditor()}
                {additionalComponents && additionalComponents(data, setData)}
                {
                    entity.relatedEntities && this.props.entityId !== null &&
                    entity.relatedEntities(this.props.entityId)?.map((relatedEntity, i) => {
                        const query = {
                            ...relatedEntity.query,
                            ...(this.state.relatedEntitiesQueries[i] || {})
                        } as DataQuery<any>;
                        return <div key={i}>
                            <h3><Trans>Related {ENTITIES[relatedEntity.entity].title}</Trans></h3>
                            <BasicEntitiesTable
                                entity={ENTITIES[relatedEntity.entity]}
                                initQuery={query}
                            />
                        </div>
                    })
                }
            </Stack>
        );
    }
}

export default EntityEditor;