import React, {createContext, Component, ReactNode, Context} from 'react';
import {incidentStatus} from "../../services/types/incidentStatus";
import {IIncident} from "../../services/types/incident";
import {
    IFailedAction,
    IMergeResult,
    ISplitResult,
    IStatusChangeResult
} from "../../services/entities/incidents/incidentManager";

export interface NotificationContextType {
    notifications: Notification[];
    addNotification: (notification: Notification) => void;
    removeNotification: (id: number) => void;
}

const NotificationContext: Context<NotificationContextType | undefined> = createContext<NotificationContextType | undefined>(undefined);

interface NotificationProviderProps {
    children: ReactNode;
}

export enum NOTIFICATION_TYPE {INCIDENT_ACTION, ERROR, SAVE, EMAIL}

export enum INCIDENT_ACTIONS {STATUS_CHANGE, MERGE, SPLIT, SAVE}

interface INotification {
    id?: number,
    notificationType: NOTIFICATION_TYPE,
    actionType: INCIDENT_ACTIONS | null,
    title?: string,
    autoHideDuration?: number,
}

interface IErrorNotification extends INotification {
    notificationType: NOTIFICATION_TYPE.ERROR,
    actionType: null,
}

interface IGenericSaveNotification extends INotification {
    notificationType: NOTIFICATION_TYPE.SAVE,
    actionType: null,
}

interface IGenericEmailNotification extends INotification {
    notificationType: NOTIFICATION_TYPE.EMAIL,
    actionType: null,
}

interface IIncidentNotification extends INotification {
    notificationType: NOTIFICATION_TYPE.INCIDENT_ACTION,
    actionType: INCIDENT_ACTIONS,
}

export interface IIncidentStatusChangeNotification extends IIncidentNotification {
    actionType: INCIDENT_ACTIONS.STATUS_CHANGE,
    data: IStatusChangeResult,
    onUndo: (data: IStatusChangeResult) => any,
}

export interface IIncidentSplitNotification extends IIncidentNotification {
    actionType: INCIDENT_ACTIONS.SPLIT,
    data: ISplitResult,
}

export interface IIncidentMergeNotification extends IIncidentNotification {
    actionType: INCIDENT_ACTIONS.MERGE,
    data: IMergeResult,
    undoSuccessCallback?: (res: ISplitResult[]) => any,
    undoErrorCallback?: (res: IFailedAction) => any,
}

export interface IIncidentSaveNotification extends IIncidentNotification {
    incidentId: number,
    stateBefore: any,
    stateAfter: any,
    actionType: INCIDENT_ACTIONS.SAVE
}

export type IncidentNotification = (
    IIncidentStatusChangeNotification |
    IIncidentSplitNotification |
    IIncidentMergeNotification |
    IIncidentSaveNotification
    )

export type ErrorNotification = (
    IErrorNotification
    )

export type SaveNotification = (
    IGenericSaveNotification
    )

export type EmailNotification = (
    IGenericEmailNotification
    )

export type Notification = (
    IncidentNotification | ErrorNotification | SaveNotification | EmailNotification | null
    )


class NotificationProvider extends Component<NotificationProviderProps, NotificationContextType> {
    constructor(props: NotificationProviderProps) {
        super(props);
        this.state = {
            notifications: [],
            addNotification: this.addNotification,
            removeNotification: this.removeNotification
        };
    }

    addNotification = (notification: Notification) => {
        if (!notification) {
            return
        }
        notification.id = Date.now();
        this.setState((prevState) => ({
            notifications: [...prevState.notifications, notification]
        }));
    };

    removeNotification = (id: number) => {
        this.setState((prevState) => ({
            notifications: prevState.notifications.filter((notification: Notification) => {
                return notification && (notification.id !== id)
            })
        }));
    };

    render() {
        return (
            <NotificationContext.Provider value={this.state}>
                {this.props.children}
            </NotificationContext.Provider>
        );
    }
}

export {NotificationProvider, NotificationContext};
