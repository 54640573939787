import React from 'react'
import TopNavBar from '../UIComponents/TopNavBar/TopNavBar';
import withRouter, {IRouterProps} from "../services/withRouter";

import {Stack} from "@mui/material";
import AlignDir from "../services/languages/AlignDir";
import EntityManager from "./entityCard/EntityManager";
import {DataItem, DataQuery} from "../services/entities/entityFields";
import {WithNotificationProps} from "../UIComponents/IncidentNotifications/withNotifications";
import {Entity} from "../services/entities/entityBase";
import {t} from "@lingui/core/macro";

interface IProps
    extends WithNotificationProps, IRouterProps {
    entity: Entity<DataItem, DataQuery<DataItem>>;
}

interface IState {
    id: number | null
}

class EntityCard
    extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const idArg = this.props.params.id;
        let id;
        try {
            id = idArg === null || idArg === t`new` ? null : parseInt(idArg);
        } catch (e) {
            id = null;
        }
        this.state = {
            id: id
        }
    }

    componentDidUpdate() {
        const idArg = this.props.params.id;
        const id = idArg === null ? null : parseInt(idArg);
        if (this.state.id !== id) {
            this.setState((curr) => ({...curr, id: id}))
        }
    }

    private setId(id: number | null) {
        this.props.navigate(`/${this.props.entity.slug}/${id}`);
    }

    render() {
        const id = this.state.id;
        return <div className={"page-wrap"}>
            <TopNavBar>
                <Stack direction={"row"} gap={2} alignItems={"center"}>
                    <div className="title-wrap">
                        {this.props.entity.title} ({this.state.id || t`new`})
                    </div>
                </Stack>
            </TopNavBar>
            <AlignDir direction={"ltr"}>
                <div className={"page-content content-wrap"}>
                    <EntityManager
                        id={id}
                        entity={this.props.entity}
                        setId={(id: number|null)=>{
                            this.setId(id)
                        }}
                    />
                </div>
            </AlignDir>
        </div>
    }
}

export default (withRouter(EntityCard));